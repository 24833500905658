<template>
  <div>
    <v-row>
      <v-col cols="12">
        <Crud
          title="Gestão de Escalas"
          buttonAddItemText="Nova Escala"
          searchLabelText="Pesquisar na lista"
          :searchTextField.sync="searchTextField"
          :formTitle="nameForm"
          :callBackModalCancelar="fechar"
          :dialog.sync="dialog"
          :callBackModalSalvar="salvar"
          :dialogDelete.sync="dialogDelete"
        >
          <template slot="data-content">
            <v-row class="mt-5">
              <!-- FILTRO CAMPOS -->
            </v-row>
          </template>
          <template slot="data-table">
            <v-data-table
              :headers="headers"
              :items="escalas"
              class="elevation-5"
              :loading="loadingTable"
            >
              <!-- ACTIONS -->
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      :loading="loadingVisualizar[`${item.id}`]"
                    >
                      <v-icon class="actions" @click="toggleModalLateral(item)">
                        mdi-eye
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Visualizar</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      :loading="loadingEditar[`${item.id}`]"
                    >
                      <v-icon class="actions" @click="editar(item)">
                        mdi-pencil
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Editar</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon class="actions" @click="remover(item, true)">
                        mdi-delete
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Deletar</span>
                </v-tooltip>
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <v-switch
                  class="mt-0 pt-0"
                  v-model="item.status"
                  @click="mudarStatus(item)"
                  :label="item.status == 'A' ? 'Ativo' : 'Inativo'"
                  color="success"
                  value="A"
                  hide-details
                  :loading="loadingStatus[`${item.id}`]"
                ></v-switch>
              </template>
              <template v-slot:[`item.created_at`]="{ item }">
                {{ item.created_at | dateFormatBr() }}
              </template>
              <template v-slot:[`item.padrao`]="{ item }">
                <v-chip small color="primary">
                  {{ item.padrao }}
                </v-chip>
              </template>
            </v-data-table>
          </template>
          <!--------------- MODAL DE DELETE --------------------------------------------- -->
          <template slot="modalDelete/titleModal">Excluir o registro?</template>
          <template slot="modalDelete/contentModal">
            <p>
              Atenção! Você está prestes a realizar uma ação que não pode ser
              desfeita.
            </p>
            <p>Você realmente deseja excluir esse registro?</p>
          </template>
          <template slot="modalDelete/actionsModal">
            <v-btn depressed @click="dialogDelete = false">Fechar</v-btn>
            <v-btn
              :loading="loadingButtonDelete"
              color="error"
              @click="remover()"
              >Excluir</v-btn
            >
            <v-spacer class="d-none d-md-block"></v-spacer>
          </template>
          <!-- ------------- MODAL DE CADASTRO --------------------------------------------- -->
          <template slot="contentModalCrud">
            <v-container fluid>
              <v-row class="ma-3" wrap>
                <!-- título -->
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    ref="titulo"
                    label="Título da escala"
                    placeholder="Escreva o título do escala"
                    required
                    outlined
                    dense
                    v-model="escala.titulo"
                    data-vv-as="titulo"
                    persistent-hint
                    :hint="errors.first('titulo')"
                    :error="errors.collect('titulo').length ? true : false"
                    v-validate="'required|min:3'"
                    data-vv-validate-on="change"
                    id="titulo"
                    name="titulo"
                  ></v-text-field>
                </v-col>
                <!-- tipo -->
                <v-col v-if="validaAcesso()" cols="12" sm="12" md="6">
                  <v-select
                    ref="tipo"
                    label="Tipo"
                    placeholder="Selecione o tipo da escala"
                    :items="SelectItensTipo"
                    dense
                    outlined
                    v-model="escala.tipo"
                    item-text="text"
                    item-value="value"
                    data-vv-as="tipo"
                    persistent-hint
                    :hint="errors.first('tipo')"
                    :error="errors.collect('tipo').length ? true : false"
                    v-validate="'required'"
                    data-vv-validate-on="change"
                    id="tipo"
                    name="tipo"
                  ></v-select>
                </v-col>
                <!-- conceito -->
                <v-col v-if="validaAcesso()" cols="12" sm="12" md="6">
                  <v-select
                    ref="conceito"
                    label="Conceito"
                    placeholder="Selecione o tipo da escala"
                    :items="SelectItensConceito"
                    dense
                    outlined
                    v-model="escala.conceito"
                    item-text="text"
                    item-value="value"
                    data-vv-as="conceito"
                    persistent-hint
                    :hint="errors.first('conceito')"
                    :error="errors.collect('conceito').length ? true : false"
                    v-validate="'required'"
                    data-vv-validate-on="change"
                    id="conceito"
                    name="conceito"
                  ></v-select>
                </v-col>
                <!-- descrição -->
                <v-col cols="12" sm="12" md="12">
                  <v-textarea
                    ref="descricao"
                    label="Descrição"
                    outlined
                    dense
                    v-model="escala.descricao"
                    data-vv-as="descrição"
                    persistent-hint
                    :hint="errors.first('descricao')"
                    :error="errors.collect('descricao').length ? true : false"
                    v-validate="'required'"
                    data-vv-validate-on="change"
                    id="descricao"
                    name="descricao"
                  ></v-textarea>
                </v-col>

                <!-- Título das opções -->
                <v-col cols="12">
                  <h2 class="text-h5">Opções</h2>
                </v-col>

                <!-- opções -->
                <v-col cols="12" sm="12" md="12">
                  <v-btn
                    dark
                    color="primary"
                    small
                    @click.stop="adicionaOpcao()"
                  >
                    <v-icon dark> mdi-plus </v-icon>
                    <span>Adicione uma Opção</span>
                  </v-btn>
                </v-col>
                <v-col class="pa-0" cols="12" sm="12" md="12">
                  <v-container class="pa-0 goal">
                    <draggable
                      v-model="escala.itens"
                      handle="#handle"
                      class="width-max-width-custom"
                    >
                      <v-card
                        loader-height="7"
                        v-for="(item, index) in escala.itens"
                        :key="item.posicao"
                        class="w-full mx-3 my-3 d-flex flex-row justify-space-between align-center"
                        min-height="3em"
                      >
                        <div
                          class="h-full d-flex flex-row justify-space-between align-center"
                          style="width: 100% !important"
                        >
                          <v-btn
                            id="handle"
                            min-width="15px"
                            elevation="0"
                            class="grabbable h-full px-0 py-6"
                          >
                            <v-icon>mdi-drag-vertical</v-icon>
                          </v-btn>
                          <div class="px-2">
                            <h4 class="text-center">
                              {{ (item.posicao = index + 1) }}
                            </h4>
                          </div>
                          <div class="px-2" :style="validaCSS()">
                            <v-text-field
                              :ref="'descricao_item' + item.id"
                              label="Item"
                              placeholder="Escreva o Item"
                              required
                              outlined
                              dense
                              hide-details
                              v-model="item.descricao"
                              :data-vv-as="'descricao_item' + item.id"
                              persistent-hint
                              :hint="errors.first('descricao_item' + item.id)"
                              :error="
                                errors.collect('descricao_item' + item.id)
                                  .length
                                  ? true
                                  : false
                              "
                              v-validate="'required'"
                              data-vv-validate-on="change"
                              :id="'descricao_item' + item.id"
                              :name="'descricao_item' + item.id"
                            ></v-text-field>
                          </div>
                          <div
                            v-if="
                              escala.conceito == 'indiferente' ? false : true
                            "
                            class="pr-2"
                            style="width: 30% !important"
                          >
                            <v-text-field
                              :ref="'value' + item.id"
                              label="Valor"
                              type="number"
                              placeholder="Valor para calculo de resultado"
                              required
                              outlined
                              dense
                              hide-details
                              v-model="item.value"
                            ></v-text-field>
                          </div>
                          <div class="pr-2">
                            <v-btn
                              class="py-5"
                              dark
                              color="red"
                              small
                              @click="removeOpcoes(item)"
                            >
                              <v-icon dark> mdi-minus </v-icon>
                            </v-btn>
                          </div>
                        </div>
                      </v-card>
                    </draggable>
                  </v-container>
                </v-col>
              </v-row>
            </v-container>
          </template>
        </Crud>
      </v-col>
    </v-row>
    <ModalLateral
      :show="modalLateral.show"
      :hide="toggleModalLateral"
      :title="modalLateral.title"
      :headers="headerModalLateral"
      :item="modalLateral.item"
    >
      <template v-slot:[`item.itens`]="{ item }">
        <div v-if="item.itens && item.itens.length > 0">
          <p v-for="opcao in item.itens" :key="opcao.id">
            {{ opcao.descricao }}
          </p>
        </div>
        <p v-else>Não tem opções.</p>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <v-chip
          :style="'background-color:' + getColor(item.status) + '!important'"
          small
          dark
        >
          {{ item.status == "A" ? "Ativo" : "Inativo" }}
        </v-chip>
      </template>
      <template v-slot:[`item.created_at`]="{ item }">
        <span
          >{{ item.created_at | dateFormatBr() }}
          {{ item.created_at | timeFormatBr() }}</span
        >
      </template>
      <template v-slot:[`item.updated_at`]="{ item }">
        <span
          >{{ item.updated_at | dateFormatBr() }}
          {{ item.updated_at | timeFormatBr() }}</span
        >
      </template>
      <template v-slot:[`item.padrao`]="{ item }">
        <v-chip small color="primary">
          {{ item.padrao }}
        </v-chip>
      </template>
    </ModalLateral>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate color="#001325" size="75">
        <img :src="logotipo" height="45" alt="" />
      </v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import logo from "@/assets/images/logos/legiio.png";
import Crud from "@/components/Crud.vue";
import ModalLateral from "@/components/ModalLateral.vue";
import draggable from "vuedraggable";

export default {
  name: "PainelEscalas",
  inject: ["$validator"],
  components: {
    draggable,
    Crud,
    ModalLateral,
  },
  data: () => ({
    logotipo: logo,
    nameForm: "Nova Escala",
    modalLateral: {
      show: false,
      item: [],
      title: "",
    },
    escala: {
      id: "",
      id_empresa: null,
      id_filial: null,
      titulo: "",
      descricao: "",
      tipo: "",
      conceito: "",
      padrao: "personalizado",
      status: "",
      itens: [
        {
          id: "",
          descricao: "Novo Item",
          value: "",
          posicao: "",
          padrao: "personalizado",
        },
      ],
      remove_options: [],
    },
    SelectItensTipo: [
      { text: "Peso", value: "peso" },
      { text: "Porcentagem", value: "porcentagem" },
      { text: "Unidade", value: "unidade" },
      { text: "Reais(R$)", value: "reais" },
    ],
    SelectItensConceito: [
      { text: "Quanto maior melhor", value: "maior_melhor" },
      { text: "Quanto menor melhor", value: "menor_melhor" },
      { text: "Indiferente", value: "indiferente" },
    ],
    searchTextField: "",
    dialog: false,
    indexEdicao: -1,
    loadingTable: false,
    loadingVisualizar: [],
    loadingEditar: [],
    loadingButtonDelete: false,
    item: [],
    dialogDelete: false,
    loadingStatus: [],
    headers: [
      { text: "Titulo", align: "start", value: "titulo" },
      { text: "Data de criação", value: "created_at" },
      { text: "Padrão", value: "padrao" },
      { text: "Status", value: "status" },
      { text: "Ações", align: "end", value: "actions", sortable: false },
    ],
    headerModalLateral: [
      { text: "Titulo", align: "start", value: "titulo" },
      { text: "Descrição", value: "descricao" },
      { text: "Tipo", value: "tipo" },
      { text: "Opções", value: "itens" },
      { text: "Data de criação", value: "created_at" },
      { text: "Data de atualização", value: "updated_at" },
      { text: "Padrão", value: "padrao" },
      { text: "Status", value: "status" },
    ],
    temPCO: true,
    temAVD: true,
  }),

  computed: {
    computedHeaders() {
      return this.headers.filter(
        (h) => !h.hide || !this.$vuetify.breakpoint[h.hide]
      );
    },
    formTitle() {
      return this.indexEdicao === -1 ? "Cadastrar escala" : "Editar escala";
    },
    escalas: {
      get() {
        return this.$store.getters["escalas/getEscalas"];
      },
    },
    loading: {
      get() {
        return this.$store.getters["escalas/getLoading"];
      },
      set(value) {
        return value;
      },
    },
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },

  created() {
    this.$store.dispatch("escalas/buscarEscalas");
  },

  methods: {
    validaCSS() {
      if (this.escala.conceito === "indiferente") {
        return "width: 100% !important";
      } else {
        return "width: 70% !important";
      }
    },
    validaAcesso() {
      if (
        this.temAVD === true ||
        (this.temPCO !== true && this.temAVD !== false)
      ) {
        this.escala.tipo = "unidade";
        this.escala.conceito = "indiferente";
        return false;
      }
    },
    // Repeater
    adicionaOpcao() {
      const item = {
        id: null,
        id_empresa: null,
        descricao: "Novo Item",
        value: "",
        posicao: "",
        padrao: "personalizado",
      };

      this.escala.itens.push(item);
    },
    removeOpcoes(item) {
      if (item.id !== null) {
        this.escala.remove_options.push(item.id);
      }

      const index = this.escala.itens.indexOf(item);
      return this.escala.itens.splice(index, 1);
    },
    //CRUD
    salvar() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.dialog = false;
          this.loadingTable = true;
          let url =
            this.indexEdicao === -1
              ? "/escalas"
              : "/escalas/" + this.escala.uuid;
          let method = this.indexEdicao === -1 ? "POST" : "PUT";
          let sucesso =
            this.indexEdicao === -1
              ? "Escala cadastrada com sucesso."
              : "Escala atualizada com sucesso.";
          let erro =
            this.indexEdicao === -1
              ? "Erro ao salvar escala."
              : "Erro ao atualizar escala.";
          let escalaItem = this.escala;
          escalaItem._method = method;
          escalaItem.url = url;
          escalaItem.padrao = "personalizado";
          this.$store
            .dispatch("escalas/salvarEscala", escalaItem)
            .then((response) => {
              if (response != "NAO_AUTORIZADO") {
                this.indexEdicao === -1
                  ? this.escalas.push(response)
                  : Object.assign(this.escalas[this.indexEdicao], this.escala);

                this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
                  visibilidade: true,
                  mensagem: sucesso,
                  cor: "green",
                });
              }
            })
            .catch((error) => {
              console.error(error);
              this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
                visibilidade: true,
                mensagem: erro,
                cor: "red",
              });
            })
            .finally(() => {
              this.fechar();
            });
        } else {
          this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
            visibilidade: true,
            mensagem: "Preencha todo formulário antes de salvar!",
            cor: "red",
          });
        }
      });
    },
    editar(item) {
      this.loadingEditar[item.id] = true;

      this.buscarTodosDadosEscala(item)
        .then((response) => {
          this.indexEdicao = this.escalas.indexOf(item);

          Object.assign(this.escala, response);

          this.nameForm = "Editar escala: " + this.escala.titulo;
          this.dialog = true;
        })
        .catch(() => {
          this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
            visibilidade: true,
            mensagem:
              "Houve um erro ao buscar todos os dados do escala selecionada.",
            cor: "red",
          });
        })
        .finally(() => {
          this.loadingEditar[item.id] = false;
        });
    },
    /**
     * @description Faz busca por todos os dados de uma escala especifica no back-end de forma assincrona.
     */
    async buscarTodosDadosEscala(item) {
      return await this.$store.dispatch(
        "escalas/buscarEscalaSelecionada",
        item.uuid
      );
    },
    fechar() {
      this.dialog = false;
      this.loadingTable = false;
      this.indexEdicao = -1;
      this.escala = {
        id: "",
        id_empresa: null,
        id_filial: null,
        titulo: "",
        descricao: "",
        tipo: "",
        conceito: "",
        padrao: "personalizado",
        status: "",
        itens: [
          {
            id: null,
            id_empresa: null,
            descricao: "Novo Item",
            value: "",
            posicao: "",
            padrao: "personalizado",
          },
        ],
        remove_options: [],
      };
    },
    remover(item, openModal = false) {
      if (openModal) {
        this.item = item;
        this.dialogDelete = true;
      } else {
        this.loadingButtonDelete = true;
        const indexItem = this.escalas.findIndex((el) => el.id == this.item.id);

        this.$store
          .dispatch("escalas/deletarEscala", this.item.uuid)
          .then((response) => {
            if (response.data === true) {
              this.escalas.splice(indexItem, 1);

              this.$store.dispatch("snackBarModule/openSnackBar", {
                color: "success",
                timeout: 3000,
                text: "Registro removido.",
              });
              this.item = {};
            }
          })
          .catch(() => {
            this.$store.dispatch("snackBarModule/openSnackBar", {
              color: "error",
              timeout: 3000,
              text: "Não foi possivel remover o registro.",
            });
          })
          .finally(() => {
            this.loadingButtonDelete = false;
            this.dialogDelete = false;
          });
      }
    },
    mudarStatus(item) {
      this.loadingStatus[item.id] = true;
      this.loadingTable = true;

      this.indexEdicao = this.escalas.indexOf(item);
      this.escala = Object.assign({}, item);
      this.escala.status =
        this.escala.status == null ? "I" : this.escala.status;

      let escalaItem = this.escala;
      escalaItem._method = "PUT";
      escalaItem.url = "/escalas/" + this.escala.uuid;
      this.$store
        .dispatch("escalas/salvarEscala", escalaItem)
        .then((response) => {
          if (response != "NAO_AUTORIZADO") {
            Object.assign(this.escalas[this.indexEdicao], this.escala);
            this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
              visibilidade: true,
              mensagem: "Escala atualizada com sucesso.",
              cor: "green",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
            visibilidade: true,
            mensagem: "Erro ao atualizar escala.",
            cor: "red",
          });
        })
        .finally(() => {
          this.loadingStatus[item.id] = false;
          this.loadingTable = false;
          this.indexEdicao = -1;
          this.escala = Object.assign(
            {},
            {
              id: "",
              titulo: "",
              descricao: "",
              tipo: "",
              conceito: "",
              padrao: "personalizado",
              status: "",
              itens: [
                {
                  id: "",
                  descricao: "Novo Item",
                  value: "",
                  posicao: "",
                  padrao: "personalizado",
                },
              ],
            }
          );
        });
    },
    //Modal Lateral
    toggleModalLateral(item) {
      if (this.modalLateral.show == false) {
        this.loadingVisualizar[item.id] = true;

        this.buscarTodosDadosEscala(item)
          .then((response) => {
            const index = this.escalas.indexOf(item);

            Object.assign(this.escalas[index], response.data);

            this.modalLateral.title = response.titulo;
            this.modalLateral.item = response ? response : [];
            this.modalLateral.show = true;
          })
          .catch(() => {
            this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
              visibilidade: true,
              mensagem:
                "Houve um erro ao buscar todos os dados do escala selecionada.",
              cor: "red",
            });
          })
          .finally(() => {
            this.loadingVisualizar[item.id] = false;
          });

        return;
      } else {
        this.modalLateral.show = false;
      }
    },
    //DATATABLE COSTUMIZATION
    getColor(status) {
      if (status == "A") return "green";
      else return "red";
    },
  },
};
</script>

<style scoped>
.grabbable {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

/* (Optional) Apply a "closed-hand" cursor during drag operation. */
.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
</style>
